#footer {
    background-color: #99ccff;
    color: #000080;
    padding: 20px;
    text-align: center;
  }
  
  #footer h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  #footer p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  
  #footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  #footer li {
    display: inline;
    margin: 0 10px;
  }
  
  #footer a {
    color: #000080;
    text-decoration: none;
  }
  
  #footer a:hover {
    text-decoration: underline;
  }
  