.schools-section {
  padding: 20px;
  text-align: center;
}

.fullscreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.carousel-item {
  flex: 0 0 33.33%; /* Mostrar tres elementos a la vez */
  display: flex;
  justify-content: center;
  align-items: center;
}
