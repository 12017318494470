 
  .experiences-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .experience-item {
    margin: 20px;
  }
  