.menu.initState {
  background-color: #b4bec7; /* Color de fondo principal */
  color: #ffffff; /* Color de texto blanco */
  transition: top 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menu.sticky {
  background-color: #b4bec7; /* Color de fondo sticky */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menu .mr-auto {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: #ffffff;
  cursor: pointer;
}

.menu .nav-link {
  color: #ffffff; /* Color de los enlaces */
  margin: 0 10px; /* Espaciado entre enlaces */
}

.menu .nav-link:hover {
  background-color: #00adf1; /* Color de fondo al pasar el ratón */
  transition: 0.5s;
  border-radius: 0px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 20px; /* Espacio a la derecha del logo */
}

.logo {
  height: 50px; /* Ajustar el tamaño del logo */
  max-height: 100%; /* Asegura que la imagen no se corte */
  width: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu {
    flex-direction: column; /* Cambiar dirección del menú en pantallas pequeñas */
  }

  .menu .mr-auto {
    justify-content: center;
    flex-direction: column;
  }

  .menu .nav-link {
    display: block;
    margin: 10px 0; /* Espaciado entre enlaces en pantallas pequeñas */
  }

  .menu .MuiIconButton-root {
    display: block; /* Muestra el botón de menú en pantallas pequeñas */
  }

  .menu .language-selector {
    margin-right: 0; /* Mueve el selector de idioma a la derecha */
  }
}
