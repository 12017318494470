#whychooseus h2 {
  margin-bottom: 20px;
  font-size: 2.5em;
}

.values-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 800px;
}

.value-item {
  background-color: #053b69;
  padding: 20px;
  border-radius: 10px;
}

.value-item h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.value-item p {
  font-size: 1em;
  line-height: 1.5;
}

.objectives {
  margin-top: 20px;
}

.objectives h3 {
  font-size: 2em;
  margin-bottom: 10px;
}

.objectives ul {
  list-style-type: none;
  padding: 0;
}

.objectives li {
  font-size: 1.2em;
  margin-bottom: 5px;
}
