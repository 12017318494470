#application-form {
  margin-top: 2rem;
}

#application-form .form-wrapper {
  background-color: #ffffff; /* Fondo blanco */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

#application-form h2 {
  color: #4a5f76; /* Azul grisáceo un poco más oscuro */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#application-form .file-name {
  margin-left: 10px;
  font-size: 0.9rem;
  color: #666;
}
