/* Estilo general de la aplicación */
.App {
  text-align: center;
  width: 100%;
}

/* Estilo del logo */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Estilo del header */
.App-header {
  background-color: #053b69; /* Color de fondo del header */
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Enlaces */
.App-link {
  color: #00adf1; /* Color de los enlaces */
}

/* Animación del logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilos adicionales para el menú */
.menu.initState {
  background-color: #053b69; /* Color de fondo principal */
  color: white; /* Color de texto blanco */
  transition: top 0.3s;
  width: 100%;
}

.menu.sticky {
  background-color: #053b69; /* Color de fondo sticky */
  width: 100%;
}

.menu .mr-auto {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
}

.menu .nav-link {
  color: white; /* Color de los enlaces */
}

.menu .nav-link:hover {
  background-color: #00adf1; /* Color de fondo al pasar el ratón */
  transition: 0.5s;
  border-radius: 0px;
}

.logo {
  height: 40px;
  margin-right: 16px;
}

/* Estilos responsivos 
@media (max-width: 600px) {
  .menu .nav-link {
    display: none;
  }
}*/
/* Estilos adicionales para el selector de idioma */
.language-selector {
  display: inline-block;
  margin-left: 10px;
}

.language-selector select {
  background-color: #053b69; /* Color de fondo del selector */
  color: white;
  border: 1px solid #00adf1; /* Borde del selector */
  padding: 5px;
  border-radius: 5px;
}

.language-selector select:hover {
  background-color: #00adf1; /* Color de fondo al pasar el ratón */
  color: #053b69; /* Color de texto al pasar el ratón */
}

/* Estilos para asegurar que los componentes ocupen toda la pantalla */
.fullscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00adf1;
  color: #fff;
}
